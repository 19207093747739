<template>
  <div class="page">
    <CompHeader title="协议页面" />
    <div class="titlebox">
      <div
        class="titleitem"
        v-for="(item, i) in titlelist"
        :key="item.id"
        :class="{ actitle: actitleindex == i }"
        @click="changetype(item.id, i)"
      >
        {{ item.title }}
      </div>
    </div>
    <div class="contentbox">
      <div class="contenttitle">{{ listdata.title }}</div>
      <div class="main" v-html="modifiedHtml"></div>
    </div>
  </div>
</template>

<script>
import { getDetailData, getArticleData } from "@/network/api.js";
export default {
  data() {
    return {
      titlelist: [],
      actitleindex: 0,
      detaikid: 2,
      listdata: {}, //内容
      originalHtml: "", // 存储从后端获取的原始 HTML
      modifiedHtml: "",
    };
  },
  created() {
    this.gettitledata();
    this.getdetai(this.detaikid);
  },

  methods: {
    changetype(v, i) {
      this.actitleindex = i;
      this.getdetai(v);
    },
    gettitledata() {
      getArticleData().then((v) => {
        this.titlelist = v.data.data;
      });
    },
    getdetai(id) {
      getDetailData(id).then((res) => {
        console.log(res);
        this.listdata = res.data.data;
        this.originalHtml = this.listdata.content;
        this.modifyImages();
      });
    },
    modifyImages() {
      // 创建一个临时的 DOM 元素以解析 HTML
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = this.originalHtml;

      // 查找所有 <img> 标签
      const images = tempDiv.querySelectorAll("img");
      images.forEach((img) => {
        img.style.width = "3.5rem"; // 调整宽度为 100%
        // 可以根据需要添加更多样式
      });

      // 将修改后的 HTML 设置到 modifiedHtml 中
      this.modifiedHtml = tempDiv.innerHTML;
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  overflow: hidden;
  padding-top: 0.55rem;
  .titlebox {
    display: flex;
    align-items: center;
    padding-left: 0.2rem;
    .titleitem {
      font-weight: 400;
      font-size: 0.14rem;
      color: #ffffff;
      margin-right: 0.08rem;
    }
    .actitle {
      font-weight: 500;
      font-size: 0.18rem;
    }
  }
  .contentbox {
    width: 3.5rem;
    margin: 0.12rem auto;
    .contenttitle {
      text-align: center;
      font-weight: 500;
      font-size: 0.2rem;
      color: #ffffff;
      margin: 0.3rem;
    }
    .main {
      width: 3.5rem;
      font-weight: 400;
      font-size: 0.12rem;
      color: #ffffff;
      margin-top: 0.12rem;
      p {
        font-size: 0.16rem;
        color: #fff;
        line-height: 1.75em;

        img {
          width: 100% !important;
          height: unset !important;
        }
      }
    }
  }
}
</style>
